<template>
    <div class="b-page-wrapper os-viewport">
        <div class="b-head-block b-head-block--link">
            <div class="b-container">
                <div class="b-head-block__wrapper"><a href="/listCr" class="b-link b-link--header" title=""><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 333 500" class="b-icon b-icon--header icon-angle-left"><path fill-rule="evenodd" clip-rule="evenodd" d="M297.8 490.8c12.2-12.2 12.2-32 0-44.2L101.4 250 297.8 53.3c12.2-12.2 12.2-32 0-44.2s-32-12.2-44.1 0L35.2 227.9c-12.2 12.2-12.2 32 0 44.2l218.5 218.7c12.2 12.3 31.9 12.3 44.1 0z"></path></svg><span>Назад</span></a>
                    <h1 class="b-title b-title--h1"> Заявление №517 </h1>
                </div>
            </div>
        </div>
        <div class="b-container">
            <div class="b-window b-window--information">
                <div action="/" class="b-form b-form--information">
                    <div class="b-form__first-lavel b-form__first-lavel--empty">
                        <div class="b-form__group" style="">
                            <div class="b-form__left"><span>Статус</span></div>
                            <div class="b-form__right"><span>Подписание соавторами</span></div>
                            <!-- <div class="b-form__right"><span>Соавторы подписали. Отправлено в Минздрав</span></div> -->
                        </div>
                        <div class="b-form__group">
                            <div class="b-form__left"><label class="b-label">Тип подачи</label></div>
                            <div class="b-form__right"><span>О разработке КР</span></div>
                        </div>
                        <div class="b-form__group">
                            <div class="b-form__left"><label class="b-label">Дата создания</label></div>
                            <div class="b-form__right"><time datetime="19.08.2019">12/06/2023</time></div>
                        </div>
                        <div class="b-form__group">
                            <div class="b-form__left"><label class="b-label">№</label></div>
                            <div class="b-form__right"><span>517</span></div>
                        </div>
                        <div class="b-form__group">
                            <div class="b-form__left"><span>Наименование КР</span></div>
                            <div class="b-form__right"><span>Врожденные деформации позвоночника</span></div>
                        </div>
                        <div class="b-form__group">
                            <div class="b-form__left"><span>Связь с Перечнем</span></div>
                            <div class="b-form__right"><span>Вне перечня</span></div>
                        </div>
                        <div class="b-form__group">
                            <div class="b-form__left"><span>Наименование заболевания</span></div>
                            <div class="b-form__right"><span>Деформирующие дорсопатии</span></div>
                        </div>
                        <div class="b-form__group">
                            <div class="b-form__left"><span>Код МКБ-10</span></div>
                            <div class="b-form__right"><span>Q67.5, Q76.3, Q76.4, Q77.8, Q77.9</span></div>
                        </div>
                        <div class="b-form__group">
                            <div class="b-form__left"><span>Возрастная группа</span></div>
                            <div class="b-form__right"><span>Взрослые</span></div>
                        </div>
                        <div class="b-form__group">
                            <div class="b-form__left"><span>Наименование разработчика</span></div>
                            <div class="b-form__right"><span>Общероссийская общественная организация «Ассоциация травмотологов-ортопедов России» (АТОР)</span></div>
                        </div>
                        <div class="b-form__group">
                            <div class="b-form__left"><span>Соавторы</span></div>
                            <div class="b-form__right"><span>Ассоциация хирургов-вертебрологов</span></div>
                        </div>
                        <div class="b-form__group">
                            <div class="b-form__left"><span>Адрес разработчика</span></div>
                            <div class="b-form__right"><span>127299, г. Москва, ул. Приорова 10</span></div>
                        </div>
                        <div class="b-form__group">
                            <div class="b-form__left"><span>Телефон разработчика</span></div>
                            <div class="b-form__right"><span>89510989776</span></div>
                        </div>
                        <div class="b-form__group">
                            <div class="b-form__left"><span>E-mail разработчика</span></div>
                            <div class="b-form__right"><span>solomyannikia@cito-priorov.ru</span></div>
                        </div>
                        <div class="b-form__group">
                            <div class="b-form__left"><span>Адрес размещения проекта КР для проведения общественного обсуждения</span></div>
                            <div class="b-form__right"><span>https://portalcr.minzdrav.gov.ru/</span></div>
                        </div>
                        <div class="b-form__group">
                            <div class="b-form__left"><span>Срок общественного обсуждения КР</span></div>
                            <div class="b-form__right"><span>От <span>14/06/2023 </span> — До <span> 23/10/2023</span></span>
                            </div>
                        </div>
                        <div class="b-form__group">
                            <div class="b-form__left"><span>Файл с КР</span></div>
                            <div class="b-form__right">
                                <div class="b-file">
                                    <span title="Файл только что подписан." class="mr-2" @click="signatureDialog = true">
                                        <svg height="10" width="10">
                                            <circle cx="5" cy="5" r="5" fill="#00cc33" />
                                            <path d="M-1,5 a1,1 0 0,0 12,0" fill="#fff" />
                                        </svg>
                                    </span>
                                    <a href="https://apiapprovecr.minzdrav.gov.ru/ProjectFiles?id=2688" title="Файл с КР" class="b-file__name"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" class="b-icon b-icon--file icon-file"><path fill-rule="evenodd" clip-rule="evenodd" d="M293.6 30.5c40.7-40.7 106.7-40.7 147.3 0 40.7 40.7 40.7 106.7 0 147.3L252.5 366.3c-24.9 24.9-65.3 24.9-90.2 0s-24.9-65.3 0-90.2l154.2-154.2c9.2-9.2 24-9.2 33.1 0 9.1 9.2 9.2 24 0 33.1L195.4 309.2c-6.6 6.6-6.6 17.3 0 24 6.6 6.6 17.3 6.6 24 0l188.4-188.4c22.4-22.4 22.4-58.7 0-81.1s-58.7-22.4-81.1 0L104.1 286.4c-38.2 38.2-38.2 100 0 138.2 38.2 38.1 100 38.1 138.2 0l188.4-188.4c9.2-9.2 24-9.2 33.1 0 9.1 9.2 9.2 24 0 33.1L275.4 457.7c-56.5 56.5-148 56.5-204.4 0-56.5-56.5-56.5-148 0-204.4L293.6 30.5z"></path></svg><span>Файл с КР (docx)</span></a>
                                </div>
                                <div class="b-file">
                                    <span title="Файл только что подписан." class="mr-2" @click="signatureDialog = true">
                                        <svg height="10" width="10">
                                            <circle cx="5" cy="5" r="5" fill="#00cc33" />
                                            <path d="M-1,5 a1,1 0 0,0 12,0" fill="#fff" />
                                        </svg>
                                    </span>
                                    <a href="https://apiapprovecr.minzdrav.gov.ru/ProjectFiles?id=2687" title="Файл с КР" class="b-file__name"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" class="b-icon b-icon--file icon-file"><path fill-rule="evenodd" clip-rule="evenodd" d="M293.6 30.5c40.7-40.7 106.7-40.7 147.3 0 40.7 40.7 40.7 106.7 0 147.3L252.5 366.3c-24.9 24.9-65.3 24.9-90.2 0s-24.9-65.3 0-90.2l154.2-154.2c9.2-9.2 24-9.2 33.1 0 9.1 9.2 9.2 24 0 33.1L195.4 309.2c-6.6 6.6-6.6 17.3 0 24 6.6 6.6 17.3 6.6 24 0l188.4-188.4c22.4-22.4 22.4-58.7 0-81.1s-58.7-22.4-81.1 0L104.1 286.4c-38.2 38.2-38.2 100 0 138.2 38.2 38.1 100 38.1 138.2 0l188.4-188.4c9.2-9.2 24-9.2 33.1 0 9.1 9.2 9.2 24 0 33.1L275.4 457.7c-56.5 56.5-148 56.5-204.4 0-56.5-56.5-56.5-148 0-204.4L293.6 30.5z"></path></svg><span>Файл с КР (pdf)</span></a></div>
                            </div>
                        </div>
                        <div class="b-form__group">
                            <div class="b-form__left"><span>Результаты общественного обсуждения</span></div>
                            <div class="b-form__right"><div class="b-file"><span title="Файл только что подписан."></span><a href="https://apiapprovecr.minzdrav.gov.ru/ProjectFiles?id=2689" title="Результаты общественного обсуждения"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" class="b-icon b-icon--file icon-file"><path fill-rule="evenodd" clip-rule="evenodd" d="M293.6 30.5c40.7-40.7 106.7-40.7 147.3 0 40.7 40.7 40.7 106.7 0 147.3L252.5 366.3c-24.9 24.9-65.3 24.9-90.2 0s-24.9-65.3 0-90.2l154.2-154.2c9.2-9.2 24-9.2 33.1 0 9.1 9.2 9.2 24 0 33.1L195.4 309.2c-6.6 6.6-6.6 17.3 0 24 6.6 6.6 17.3 6.6 24 0l188.4-188.4c22.4-22.4 22.4-58.7 0-81.1s-58.7-22.4-81.1 0L104.1 286.4c-38.2 38.2-38.2 100 0 138.2 38.2 38.1 100 38.1 138.2 0l188.4-188.4c9.2-9.2 24-9.2 33.1 0 9.1 9.2 9.2 24 0 33.1L275.4 457.7c-56.5 56.5-148 56.5-204.4 0-56.5-56.5-56.5-148 0-204.4L293.6 30.5z"></path></svg><span>Результаты обсуждения</span></a></div></div>
                        </div>
                    </div>
                    <div class="b-form__group b-form__group--button">
                        <button class="b-button b-button--fill b-button--full"><span>Подписать</span></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="b-voting-results" v-if="signatureDialog" @click.self="signatureDialog = false">
            <div class="b-voting-results__content">
                <p class="b-voting-results__text">Подпись документа</p>
                <p class="text-left ml-5">Тип документа: Проект КР</p>
                <table class="b-voting-results__table">
                    <tr>
                        <th>
                            <span>МПНКО</span>
                        </th>
                        <th>
                            <span>ФИО</span>
                        </th>
                        <th>
                            <span>Статус</span>
                        </th>
                    </tr>
                    <tr>
                        <td>
                            <span class="b-voting-results__text-value">
                                Общероссийская общественная организация «Ассоциация травмотологов-ортопедов России» (АТОР)
                            </span>
                        </td>
                        <td>
                            <span class="b-voting-results__text-value">
                                Иванов П. А.
                            </span>
                        </td>
                        <td>
                            <span class="b-voting-results__text-value">
                                Подписан 25/10/2023
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <span class="b-voting-results__text-value">
                                Ассоциация хирургов-вертебрологов
                            </span>
                        </td>
                        <td>
                            <span class="b-voting-results__text-value">
                                Петрова О. С.
                            </span>
                        </td>
                        <td>
                            <span class="b-voting-results__text-value">
                                Ожидает подписи
                            </span>
                        </td>
                    </tr>
                </table>
                <button
                    @click="signatureDialog = false"
                    class="b-voting-results-button b-button b-button--cancel"
                >
                    Закрыть
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({
        signatureDialog: false
    })
}
</script>

<style>
@import '../../style/stylePages.css';

.modal-mask {
	position: fixed;
	z-index: 9998;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: table;
	transition: opacity 0.3s ease;
}

.modal-wrapper {
	display: table-cell;
	vertical-align: middle;
}

.modal-container {
	width: 700px;
	margin: 0px auto;
	/* padding: 20px 30px; */
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
	transition: all 0.3s ease;
	font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
	margin-top: 0;
	color: #42b983;
}
.modal-header {
	text-align: center;
	background: #003b84;
	color: white;
	font-size: 19px;
	border-bottom: 1px solid lightgray;
	padding: 10px;
}
.modal-footer {
	text-align: center;
	padding: 10px 20px;
}

.modal-body {
	margin: 20px 0;
	padding: 20px 100px;
}

.label {
	font-size: 18px;
}
.label-marg {
	margin-top: 15px;
}

.inp {
	padding: 10px;
	border-radius: 10px;
}
.inp:hover {
	background-color: #fafafa;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
	transition: 0.3s box-shadow;
}

.modal-enter {
	opacity: 0;
}

.modal-leave-active {
	opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}
table {
	border-collapse: collapse;
}

table td,
th {
	min-width: 50px;
	text-align: center;
}

th,
td {
	border: 1px solid #000;
	padding: 5px;
}
</style>
